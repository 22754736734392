
import { defineComponent, ref } from 'vue'
import { senderSettingsOptions } from '@/definitions/_general/_data/optionsList'
import ComposeSideButtonSenderSettings
  from '@/components/shared/compose/sideButtons/ComposeSideButtonSenderSettings.vue'
import ComposeFieldWrapper from '@/components/shared/compose/ComposeFieldWrapper.vue'

export default defineComponent({
  name: 'SenderSettings',
  components: { ComposeFieldWrapper, ComposeSideButtonSenderSettings },
  setup() {
    const value = ref(senderSettingsOptions[0])

    return {
      senderSettingsOptions,
      value,
    }
  },
})
