import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_compose_side_button_sender_settings = _resolveComponent("compose-side-button-sender-settings")!
  const _component_compose_field_wrapper = _resolveComponent("compose-field-wrapper")!

  return (_openBlock(), _createBlock(_component_compose_field_wrapper, { "side-buttons-center": "" }, {
    "side-buttons": _withCtx(() => [
      _createVNode(_component_compose_side_button_sender_settings)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_field, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        type: "selectSearchCountry",
        options: _ctx.senderSettingsOptions,
        placeholder: "Start typing a number or contact name"
      }, null, 8, ["modelValue", "options"])
    ]),
    _: 1
  }))
}