
import { defineComponent, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useComposeOpenModal from '@/compositions/compose/useComposeOpenModal'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'ComposeFooter',
  components: { TmButton, TmDropdown, TmDropdownItem },
  props: {
    scheduleOpen: {
      type: Boolean,
    },
    hidePreview: {
      type: Boolean,
    },
    largeSession: {
      type: Boolean,
    },
  },
  emits: ['update:schedule-open'],
  setup(props, context) {
    const { openModalPreview } = useComposeOpenModal()
    const { isSmMax } = useBreakpoints()
    const toggleSchedule = (val = true) => {
      context.emit('update:schedule-open', val)
    }
    const scheduleBtnText = computed(() => {
      if (props.scheduleOpen) { return 'Cancel' }
      if (isSmMax.value) { return 'Schedule' }

      return 'Schedule message'
    })

    return {
      scheduleBtnText,
      openModalPreview,
      isSmMax,
      toggleSchedule,
    }
  },
})
